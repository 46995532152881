import React from 'react';
import { Link } from 'gatsby';

import HeadOverride from '../components/HeadOverride';
import Notice from '../components/Notice';
import ContentWrapper from '../components/ContentWrapper';
import CompanyInfo from '../components/CompanyInfo';

export default () => (
  <>
    <HeadOverride title="Resource not found" />

    <ContentWrapper withBorders>
      <CompanyInfo />

      <Notice type="error">
        <p>
          <strong>The requested resource cannot be found</strong>
          <br />
          We apologize for any inconvenience.
        </p>

        <p>
          <Link to="/">View Index</Link>
        </p>
      </Notice>
    </ContentWrapper>
  </>
);
